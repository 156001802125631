<template>
  <div class='hs-player'
       :class="{'active': enterPlayer, 'none-mouse': !showCursor}"
       ref="playerWrap"
       @contextmenu="onContentMenu"
       @mousedown="onMouseDown"
       @mouseup="onMouseUp"
       @mousemove="onMouseMove"
       @mouseenter="enterPlayer = true"
       @mouseleave="enterPlayer = false">
    <div class="header">
      <div class="title"
           v-show="title">{{title}}</div>
    </div>
    <video :autoplay="autoPlay"
           :poster="poster"
           ref="videoElement"
           :crossorigin="options.crossOrigin"></video>
    <!-- 视频时长 -->
    <div class="video-time"
         v-if="isShowduration">{{durationTime(duration)}}</div>
    <div class="hs-player-status">
      <!-- 点击播放 -->
      <i class="hsp-icon hsp-icon-bofang4 play-btn"
         @click="switchPlay"
         v-if="!playing && !isStalled && !isError"></i>

      <div class="loading"
           v-show="isStalled">
        <div class="circle"></div>
        <div class="loading-title">缓冲中...</div>
      </div>

      <div class="hs-player-error"
           v-show="isError">
        <p>{{notice}}<span @click="reLoad"
                class="retry"
                :style="{color: theme}">重试</span></p>
      </div>
    </div>

    <!-- 右键菜单 -->
    <div class="hs-player-menus"
         ref="contextMenu"
         v-show="showContextMenu">
      <div class="context"
           v-for="(ctx,index) in contexts"
           @click="ctx.fun(videoRef)"
           :key="index">{{ctx.title}}</div>
    </div>

    <!-- 控制器 -->
    <div class="hs-player-controller"
         :class="{'none': !showController}">
      <!-- 进度条 -->
      <div class="hs-progress"
           @click="setProgress"
           ref=REF_Progerss>
        <div class="bar-wrap">
          <div class="hs-progress-current"
               :style="{width:`${live ? '100' : progress}%`, background: theme}"></div>
        </div>
      </div>
      <div class="controller-left-btns">
        <!-- 播放/暂停 -->
        <i class="hsp-icon"
           :class="[playing ? 'hsp-icon-bofang' : 'hsp-icon-bofang3']"
           @click="switchPlay"></i>
        <!-- 音量控制 -->
        <div class="volume">
          <i class="hsp-icon hsp-icon-shengyin"
             @click="showVolumeBar = !showVolumeBar"></i>
          <div class="volume-bar-wrap"
               id="volume-bar-wrap"
               :style="{display: showVolumeBar ? 'block' : 'none'}"
               @mousemove="setVolume"
               @mouseleave="showVolumeBar = false">
            <div class="volume-bar"
                 id="volume-bar">
              <div class="progress"
                   id="progress"
                   :style="{height: `${volume * 100}%`, background: theme}"></div>
            </div>
          </div>
        </div>
        <!-- 直播状态 -->
        <i class="hsp-icon hsp-icon-live living"
           :style="{color: theme}"
           v-if="live"></i>
        <span class="time"
              v-if="!live">
          {{formatTime(time)}}
        </span>
      </div>
      <div class="controller-right-btns">
        <div class="speed"
             v-if="enableSpeedSetting">
          <span class="current-speed"
                @click="showSpeedBar = !showSpeedBar">{{currentSpeed}}x</span>
          <div class="speed-bar-wrap"
               :style="{display: showSpeedBar ? 'block': 'none'}">
            <div @click="setSpeed(speed)"
                 @mouseenter="setColor($event, theme)"
                 @mouseleave="setColor($event, 'transparent')"
                 class="speed-item"
                 v-for="speed in speedList"
                 :key="speed">{{speed}}x</div>
          </div>
        </div>
        <i class="hsp-icon hsp-icon-quanping"
           @click="fullScreen"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core'
import { formatTime, resolvettUrl, captureToBase64 } from './util'
import { EventManger } from './EventManger'
import ErrorMsg from './ErrorMsg'
import EVENTS_LIST from './EventList'
import HLS from 'hls.js'
import sysContextMenu from './contextMenu'
let mouseDown = false

function generateGetBoundingClientRect (x = 0, y = 0) {
  return () => ({
    width: 0,
    height: 0,
    top: y,
    right: x,
    bottom: y,
    left: x
  })
}

/**
 * options.url // 视频地址
 * options.poster // 封面地址
 */
export default {
  props: {
    options: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  created () {
    // 创建事件管理器
    this.em = new EventManger()
  },
  data () {
    return {
      contexts: [], // 鼠标右键菜单
      showContextMenu: false,
      enterPlayer: false, // 鼠标进入播放器
      videoUrl: '', // 真实视频地址
      progress: 0, // 播放进度（百分比）
      title: '', // 视频标题
      playing: false, // 播放中
      autoPlay: false, // 启用自动播放
      poster: '', // 封面
      live: false, // 是否为直播模式
      time: [0, 0], // 当前播放进度（秒级）
      duration: 0,
      isShowduration: true,
      isFullScreen: false, // 是否全屏
      isError: false, // 错误
      isStalled: false, // 阻塞
      isPaused: false, // 暂停
      theme: '#e74c3c',
      singlePlay: true, // 同时启用一个播放器
      showController: true,
      showCursor: true,
      volume: 1,
      notice: '',
      ttCode: '', // 头条视频code
      showVolumeBar: false,
      showSpeedBar: false,
      currentSpeed: 1.0,
      enableSpeedSetting: false,
      speedList: [0.5, 1.0, 1.25, 1.5, 1.75, 2.0]
    }
  },
  watch: {
    options: {
      handler (val) {
        this.control = typeof val.control === 'boolean' ? val.control : true // 是否显示控制栏，默认为true
        this.poster = val.poster
        this.live = val.live
        this.autoPlay = val.autoPlay
        this.title = val.title
        this.videoUrl = val.url || ''
        this.ttCode = val.ttCode
        this.singlePlay = val.singlePlay || true
        this.theme = val.theme || '#e74c3c'
        this.enableSpeedSetting = val.enableSpeedSetting || false
        this.duration = val.duration
      },
      immediate: true
    },
    enterPlayer () {
      this.showSpeedBar = false
      this.showVolumeBar = false
    }
  },
  methods: {
    // 用于处理播放时长的展示
    formatTime (val) {
      return val.map(item => {
        return formatTime(item)
      }).join(' / ')
    },
    reLoad () {
      this.videoRef.src = null
      this.videoRef.src = this.videoUrl
      this.videoRef.load()
    },
    /**
     * 截取当前画面
     * @param {String} [fileName = timestamp] 文件名称
     * @returns {Promise} File
     * @public
     */
    capture (fileName = `capture_${Date.now()}`) {
      const video = this.videoRef
      const width = video.videoWidth
      const height = video.videoHeight
      return captureToBase64(this.videoRef, fileName, width, height)
    },
    onMouseDown () {
      mouseDown = true
    },
    onMouseUp () {
      mouseDown = false
    },
    initContextMenu () {
      if (!this.options.contextMenu) return
      this.options.contextMenu.forEach(context => {
        if (typeof context === 'string') {
          const menu = sysContextMenu.find(item => item.id === context)
          if (menu) {
            this.contexts.push(menu)
          }
        } else if (typeof context === 'object') {
          this.contexts.push(context)
        }
      })
    },
    removeContextMenu () {
      this.showContextMenu = false
      document.removeEventListener('click', this.removeContextMenu)
    },
    // 鼠标右键事件
    onContentMenu (evt) {
      evt.preventDefault()
      if (this.contexts.length < 1) return
      this.showContextMenu = true
      document.addEventListener('click', this.removeContextMenu)
      if (this.cursor) {
        this.cursor.getBoundingClientRect = generateGetBoundingClientRect(evt.clientX, evt.clientY)
        this.contextMenuPosition.update()
        return
      } else {
        this.cursor = {
          getBoundingClientRect: generateGetBoundingClientRect(evt.clientX, evt.clientY)
        }
      }
      this.contextMenuPosition = createPopper(this.cursor, this.contextRef, {
        modifiers: [
          {
            name: 'preventOverflow',
            options: {
              altBoundary: true,
              boundary: this.wrapRef
            }
          }
        ]
      })
    },
    setColor (evt, color) {
      evt.target.style.background = color
    },
    /**
     * 设置播放速度
     * @param {Number} [speed = 1] 播放速度 0.5 - 2
     * @public
     */
    setSpeed (speed = 1) {
      this.videoRef.playbackRate = speed
      this.currentSpeed = speed
    },
    // 设置音量
    setVolume (evt) {
      if (!mouseDown) {
        return
      }
      evt.stopPropagation()
      evt.preventDefault()
      let pos = evt.offsetY - 10
      if (evt.target.id === 'progress') {
        pos = evt.offsetY + evt.target.offsetTop
      }
      if (pos < 0 || pos > 100) {
        return
      }
      this.volume = Number((1 - pos / 100).toFixed(2))
      this.videoRef.volume = this.volume
    },
    // 设置播放进度
    setProgress (evt) {
      if (this.live || !this.control) {
        return
      }
      const mouseX = evt.offsetX
      const bar = this.$refs.REF_Progerss
      const precent = mouseX / bar.offsetWidth
      this.videoRef.currentTime = Math.floor(this.videoRef.duration * precent)
    },
    // 初始化播放器
    async initPlayer () {
      try {
        if (this.ttCode) {
          const url = await resolvettUrl(this.ttCode)
          this.videoRef.src = url
        } else {
          this.videoRef.src = this.videoUrl
        }
      } catch (err) {
        this.switchPlayerStatus('isError')
        this.notice = '视频加载失败'
        return
      }
      this.initEventListener(this.videoRef)
      if (this.isHLS) {
        const hls = new HLS()
        hls.loadSource(this.videoUrl)
        hls.attachMedia(this.videoRef)
        // HLS数据流解析成功事件处理器
        hls.on(HLS.Events.MANIFEST_PARSED, () => {
          if (this.autoPlay) {
            this.videoRef.play()
          }
        })
        // HLS事件监听器初始化
        this.initHlsEvent(hls)
        this.hls = hls
      }
      this.initContextMenu()
    },
    // 绑定事件监听器
    initEventListener (video) {
      // 播放器对视频事件的监听
      video.addEventListener('click', this.switchPlay)
      video.addEventListener('timeupdate', this.onTimeUpdate)
      video.addEventListener('pause', this.onPause)
      video.addEventListener('stalled', this.onStalled)
      video.addEventListener('playing', this.onPlaying)
      video.addEventListener('loadstart', this.onLoadStart)
      video.addEventListener('loadedmetadata', this.onLoadedMetaData)
      video.addEventListener('volumechange', this.onVolumeChange)
      video.addEventListener('error', this.onVideoError)
      video.addEventListener('waiting', this.onStalled)
      document.addEventListener('fullscreenchange', this.onFullScreenChage)

      // 用户的事件监听
      if (this.options.on) {
        Object.keys(this.options.on).forEach(eventName => {
          if (EVENTS_LIST.VIDEO_EVENTS.includes(eventName)) {
            video.addEventListener(eventName, this.options.on[eventName])
          } else if (EVENTS_LIST.PLAYER_EVENTS.includes(eventName)) {
            this.em.add(eventName, this.options.on[eventName])
          }
        })
      }
    },

    // 注销播放器事件
    destroyEvent () {
      if (!this.videoRef) {
        return
      }
      this.videoRef.removeEventListener('click', this.switchPlay)
      this.videoRef.removeEventListener('timeupdate', this.onTimeUpdate)
      this.videoRef.removeEventListener('pause', this.onPause)
      this.videoRef.removeEventListener('stalled', this.onStalled)
      this.videoRef.removeEventListener('playing', this.onPlaying)
      this.videoRef.removeEventListener('loadstart', this.onLoadStart)
      this.videoRef.removeEventListener('loadedmetadata', this.onLoadedMetaData)
      this.videoRef.removeEventListener('error', this.onVideoError)
      this.videoRef.removeEventListener('volumechange', this.onVolumeChange)
      this.videoRef.removeEventListener('waiting', this.onStalled)
      document.removeEventListener('fullscreenchange', this.onFullScreenChage)
    },
    // 设置播放状态
    switchPlayerStatus (status) {
      const STATUS_LIST = ['playing', 'isStalled', 'isPaused', 'loadstart', 'isError']
      this.showVolumeBar = false
      this.showSpeedBar = false
      STATUS_LIST.forEach(item => {
        this[item] = status === item
      })
    },
    // 鼠标在播放器内移动
    onMouseMove (evt) {
      this.showController = this.control
      this.showCursor = true
      if (!this.control) {
        return
      }
      if (this.mouseTimer) {
        clearTimeout(this.mouseTimer)
      }
      this.mouseTimer = setTimeout(() => {
        this.showController = false
        this.showVolumeBar = false
        this.showSpeedBar = false
        this.showCursor = false
        clearTimeout(this.mouseTimer)
      }, 2000)
    },
    // 加载视频数据
    onLoadStart (evt) {
      this.isStalled = true
      this.playing = false
      this.isPaused = false
    },
    // 视频播放失败
    onVideoError (evt) {
      this.notice = ErrorMsg.video[evt.target.error.code]
      this.isError = true
      this.switchPlayerStatus('isError')
    },
    onLoadedMetaData (evt) {
      this.switchPlayerStatus('isPaused')
    },
    onPause (evt) {
      this.switchPlayerStatus('isPaused')
    },
    onFullScreenChage (evt) {
      this.isFullScreen = !this.isFullScreen
    },
    onPlaying (evt) {
      if (window._HSPVPLAYER_ && this.singlePlay && window._HSPVPLAYER_ !== this.videoRef && !window._HSPVPLAYER_.paused) {
        window._HSPVPLAYER_.pause()
      }
      window._HSPVPLAYER_ = this.videoRef
      this.switchPlayerStatus('playing')
    },
    onStalled (evt) {
      this.switchPlayerStatus('isStalled')
    },
    onTimeUpdate (evt) {
      this.time = [this.videoRef.currentTime || 0, this.videoRef.duration || 0]
      this.progress = this.videoRef.currentTime / this.videoRef.duration * 100
    },
    onVolumeChange (evt) {
      this.volume = evt.target.volume
    },
    initHlsEvent (hls) {
      hls.on(HLS.Events.ERROR, this.hlsListener)
    },
    fullScreen () {
      if (this.isFullScreen) {
        document.exitFullscreen()
        this.em.emit('fullscreenchange', false)
      } else {
        this.$refs.playerWrap.requestFullscreen()
        this.em.emit('fullscreenchange', true)
      }
    },
    // 直播事件监听器
    hlsListener (evt, data) {
      if (data.fatal) { // 致命错误处理
        this.switchPlayerStatus('isError')
        this.notice = '视频加载失败'
      }
    },
    // 播放/暂停状态切换
    switchPlay () {
      this.isShowduration = false
      if (this.playing) {
        this.videoRef.pause()
      } else {
        this.videoRef.play()
      }
    },
    durationTime (seconds = 0) {
      const _h = Math.floor(seconds / 3600)
      const _m = Math.floor((seconds - _h * 3600) / 60)
      const _s = Math.floor(seconds - _h * 3600 - _m * 60)
      const h = _h >= 10 ? _h : '0' + _h
      const m = _m >= 10 ? _m : '0' + _m
      const s = _s >= 10 ? _s : '0' + _s
      const result = `${m}:${s}`
      if (_h) {
        return `${h}:${result}`
      }
      return result
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.initPlayer()
    })
  },
  beforeUnmount () {
    this.hls && this.hls.destroy()
    this.destroyEvent()
  },
  computed: {
    videoRef () {
      return this.$refs.videoElement
    },
    contextRef () {
      return this.$refs.contextMenu
    },
    wrapRef () {
      return this.$refs.playerWrap
    },
    isHLS () {
      return /\.m3u8$/.test(this.videoUrl)
    }
  }
}
</script>
<style lang="scss" scoped>
@keyframes circle {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.hs-player {
  position: relative;
  background: #000;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: 14px;
  border-radius: 4px 4px 0px 0px;
  &.none-mouse {
    cursor: none;
  }
  &.active {
    .hs-player-controller {
      padding: 0 10px;
      height: 50px;
    }
  }
  .video-time {
    position: absolute;
    right: 20px;
    bottom: 20px;
    color: #fff;
    font-size: 12px;
  }
  .header {
    color: #fff;
    padding: 10px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    box-sizing: border-box;
  }
  .loading {
    width: 80px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .circle {
      width: 25px;
      height: 25px;
      margin: 0 auto;
      animation: circle infinite 0.75s linear;
      border: 2px solid #fff;
      border-top-color: rgba(0, 0, 0, 0.5);
      border-right-color: rgba(0, 0, 0, 0.5);
      border-bottom-color: rgba(0, 0, 0, 0.5);
      border-radius: 100%;
    }
    .loading-title {
      text-align: center;
      margin-top: 10px;
    }
  }

  video {
    width: 100%;
    height: 100%;
    background: #000;
    display: block;
    margin: 0 auto;
  }
  .hs-player-menus {
    position: absolute;
    left: 40px;
    top: 40px;
    color: #fff;
    font-size: 12px;
    background: rgba(14, 8, 8, 0.342);
    .context {
      padding: 5px 10px;
      &:hover {
        background: rgba(83, 82, 82, 0.829);
        cursor: pointer;
      }
    }
  }
  .hs-player-controller {
    position: absolute;
    color: #fff;
    bottom: 0;
    left: 0;
    right: 0;
    height: 8px;
    transition: all 0.3s;
    box-sizing: border-box;
    &.none {
      height: 8px;
      padding: 0;
    }
    &::after {
      display: block;
      content: "";
      box-shadow: 0 40px 28px 25px rgba(0, 0, 0, 0.5);
    }
    :deep .hsp-icon {
      font-size: 16px;
    }
    // 音量控制
    .volume {
      display: inline-block;
      position: relative;
      .volume-bar-wrap {
        background: rgba(0, 0, 0, 0.64);
        position: absolute;
        bottom: 30px;
        padding: 10px;
        left: -5px;
        border-radius: 3px;
      }
      .volume-bar {
        width: 3px;
        height: 100px;
        position: relative;
        background: #ccc;
        .progress {
          background: #e74c3c;
          width: 100%;
          height: 40%;
          position: absolute;
          bottom: 0;
          &::after {
            display: block;
            position: absolute;
            content: "";
            width: 10px;
            height: 10px;
            background: inherit;
            border-radius: 50%;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
    // 倍速控制
    .current-speed {
      font-size: 12px;
    }
    .speed {
      display: inline-block;
      position: relative;
      font-size: 12px;
      .speed-bar-wrap {
        background: rgba(0, 0, 0, 0.64);
        position: absolute;
        bottom: 30px;
        padding: 4px 0;
        left: -5px;
        border-radius: 3px;
      }
      .speed-item {
        padding: 4px;
        text-align: center;
      }
    }
    // 进度条
    .hs-progress {
      position: relative;
      width: 100%;
      border-radius: 4px;
      overflow: hidden;
      padding: 5px 0;
      cursor: pointer;
      .bar-wrap {
        height: 3px;
        position: relative;
        background: #ccc;
      }
      .hs-progress-current {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background: #e74c3c;
      }
    }
    .controller-left-btns,
    .controller-right-btns {
      & > * {
        cursor: pointer;
      }
    }
    .controller-left-btns {
      float: left;
      & > * {
        margin-right: 6px;
      }
      .living {
        color: #e74c3c;
        background: #fff;
        border-radius: 4px;
      }
      .time {
        font-size: 12px;
      }
    }
    .controller-right-btns {
      float: right;
      & > * {
        margin-left: 10px;
      }
    }
  }
  .hs-player-status {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    display: none;
    p {
      background: rgba(0, 0, 0, 0.3);
      padding: 5px 0;
      border-radius: 4px;
    }
    .play-btn {
      box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.3);
      background: rgba(0, 0, 0, 0.5);
      border-radius: 50%;
    }
    .retry {
      color: #e74c3c;
      padding-left: 10px;
      cursor: pointer;
      font-size: 12px;
    }
    i {
      font-size: 40px;
      cursor: pointer;
    }
  }
  &:hover {
    .hs-player-status {
      display: block;
    }
    .video-time {
      display: none;
    }
  }
}
</style>

/** 详情路由 */

// 详情页
const Detail = () => import('@/views/detailModule/detail')
const homeRouter = [
  {
    path: '/detail',
    name: 'detail',
    component: Detail,
    meta: {
      title: '详情页'
    }
  }
]
export default homeRouter

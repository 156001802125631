/* eslint-disable */
import jsonp from 'jsonp'
/**
 * 将秒数转化成时间格式
 * @param {Number} seconds 秒
 * @returns HH:mm:ss
 */
function formatTime (seconds = 0) {
  const _h = Math.floor(seconds / 3600)
  const _m = Math.floor((seconds - _h * 3600) / 60)
  const _s = Math.floor(seconds - _h * 3600 - _m * 60)
  const h = _h >= 10 ? _h : '0' + _h
  const m = _m >= 10 ? _m : '0' + _m
  const s = _s >= 10 ? _s : '0' + _s
  const result = `${m}:${s}`
  if (_h) {
    return `${h}:${result}`
  }
  return result
}

/**
 * 头条code解码获取地址
 * @param {String} e 头条视频 code
 */
function ttdecode (e) {
  return new Promise((resolve, reject) => {
    var t = {}
    var n = ''
    t.href = e
    var r = (function () {
      for (var e = 0, t = new Array(256), r = 0; r !== 256; ++r) {
        e = r,
          e = 1 & e ? -306674912 ^ e >>> 1 : e >>> 1,
          e = 1 & e ? -306674912 ^ e >>> 1 : e >>> 1,
          e = 1 & e ? -306674912 ^ e >>> 1 : e >>> 1,
          e = 1 & e ? -306674912 ^ e >>> 1 : e >>> 1,
          e = 1 & e ? -306674912 ^ e >>> 1 : e >>> 1,
          e = 1 & e ? -306674912 ^ e >>> 1 : e >>> 1,
          e = 1 & e ? -306674912 ^ e >>> 1 : e >>> 1,
          e = 1 & e ? -306674912 ^ e >>> 1 : e >>> 1,
          t[r] = e
      }
      return typeof Int32Array !== 'undefined' ? new Int32Array(t) : t
    }()),
      n = '/video/urls/v/1/toutiao/mp4/' + e + '?r=' + Math.random().toString(10).substring(2)
    n[0] !== '/' && (n = '/' + n)
    var o = (function (e) {
      for (var t, n, o = -1, i = 0, a = e.length; i < a;) {
        t = e.charCodeAt(i++),
          t < 128 ? o = o >>> 8 ^ r[255 & (o ^ t)] : t < 2048 ? (o = o >>> 8 ^ r[255 & (o ^ (192 | t >> 6 & 31))],
            o = o >>> 8 ^ r[255 & (o ^ (128 | 63 & t))]) : t >= 55296 && t < 57344 ? (t = 64 + (1023 & t),
              n = 1023 & e.charCodeAt(i++),
              o = o >>> 8 ^ r[255 & (o ^ (240 | t >> 8 & 7))],
              o = o >>> 8 ^ r[255 & (o ^ (128 | t >> 2 & 63))],
              o = o >>> 8 ^ r[255 & (o ^ (128 | n >> 6 & 15 | (3 & t) << 4))],
              o = o >>> 8 ^ r[255 & (o ^ (128 | 63 & n))]) : (o = o >>> 8 ^ r[255 & (o ^ (224 | t >> 12 & 15))],
                o = o >>> 8 ^ r[255 & (o ^ (128 | t >> 6 & 63))],
                o = o >>> 8 ^ r[255 & (o ^ (128 | 63 & t))])
      }
      return -1 ^ o
    }(n)) >>> 0
    var url = 'https://ib.365yg.com' + n + '&s=' + o
    resolve(url)
  })
}
/**
 * 解析头条视频地址
 * @param {String} code // 头条视频code
 */
async function resolvettUrl (code) {
  const url = await ttdecode(code)
  return new Promise((resolve, reject) => {
    jsonp(url, (err, data) => {
      if (err) {
        reject(err)
        return
      }
      const videos = data.data.video_list
      const videoUrl = atob(videos[Object.keys(videos)[0]].main_url).replace(/^http/, 'https')
      resolve(videoUrl)
    })
  })
}


function captureToBase64 (video, name, width, height) {
  const canvas = document.createElement('canvas')
  const cvs = canvas.getContext('2d')
  canvas.width = width
  canvas.height = height

  cvs.drawImage(video, 0, 0, width, height)

  return new Promise((resolve, reject) => {
    try {
      canvas.toBlob((blob) => {
        const file = new File([blob], name)
        resolve(file)
      })
    } catch (err) {
      reject(err)
    }
  })
}

export {
  formatTime,
  ttdecode,
  resolvettUrl,
  captureToBase64
}

/** js工具 */
const getType = function (data) {
  return Object.prototype.toString.call(data).match(/\s(\w+)/)[1]
}

// 将formdata中的数据转为对象
function expandFormData (form) {
  const data = {}
  for (const [k, v] of form.entries()) {
    if (!['File', 'Undefined'].includes(getType(v))) {
      data[k] = v
    }
  }
  return data
}

/**
 * 获取城市数据 （tree结构）
 */
const getCitysTree = function (data) {
  const provinces = []
  const citys = []
  data.forEach(item => {
    item.idList = item.children
    item.label = item.name
    item.children = []
    switch (item.level) {
      case 1:
        provinces.push(item)
        break
      case 2:
        citys.push(item)
        break
    }
  })
  provinces.forEach(item1 => {
    item1.idList.forEach(item2 => {
      citys.forEach(item3 => {
        if (item2 === item3.id) item1.children.push(item3)
        if (item3.children && item3.children.length === 0) delete item3.children
      })
    })
    if (item1.children.length === 0) delete item1.children
  })
  return provinces
}

/**
 * 获取区县数据 （tree结构）
 */

const getCountyTree = function (data) {
  const jsonData = JSON.parse(JSON.stringify(data)).filter(item => item.children)
  const provinces = []
  const citys = []
  const countys = []
  jsonData.forEach(item => {
    item.idList = item.children
    item.label = item.name
    item.children = []
    switch (item.level) {
      case 1:
        provinces.push(item)
        break
      case 2:
        citys.push(item)
        break
      case 3:
        countys.push(item)
        break
    }
  })
  provinces.forEach(item1 => {
    item1.idList.forEach(item2 => {
      citys.forEach(item3 => {
        if (+item2 === +item3.id) item1.children.push(item3)
      })
      countys.forEach(item4 => {
        if (+item2 === +item4.id) item1.children.push(item4)
        if (item4.children && item4.children.length === 0) delete item4.children
      })
    })
    if (item1.children.length === 0) delete item1.children
  })
  citys.forEach(item5 => {
    item5.idList.forEach(item6 => {
      countys.forEach(item7 => {
        if (+item6 === +item7.id) item5.children.push(item7)
        if (item7.children && item7.children.length === 0) delete item7.children
      })
    })
    if (item5.children && item5.children.length === 0) delete item5.children
  })
  return provinces
}
export {
  getType,
  expandFormData,
  getCitysTree,
  getCountyTree
}

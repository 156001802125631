/** 登录路由 */

// 登录
const Login = () => import('@/views/loginModule/index')
const loginRouter = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: '首页'
    }
  }
]
export default loginRouter


/* 错误相关路由 */

// 404
const Page404 = () => import('@/views/errorModule/index')

const errorRouter = [
  {
    path: '/:pathMatch(.*)*',
    name: 'notfound',
    component: Page404
  }

]
export default errorRouter

/**
 * 书写规则：
 * 基于axios二次封装，接口和方法写一起，页面只需要传入参数即可；
 * 方法名：get请求：getName,post请求：postName；
 */
/**
 * 认证
 */

import { HTTP_INSHOOT } from '../axios'

//  认证列表
export const getAuditList = params => {
  return HTTP_INSHOOT.get('/admin/Cert/certlist', params)
}
//  认证详情
export const getAuditDetail = params => {
  return HTTP_INSHOOT.get('/admin/Cert/certinfo', params)
}

// 通过驳回
export const certexamine = params => {
  return HTTP_INSHOOT.post('/admin/Cert/certexamine', params)
}
//  注册
export const postLogin = params => {
  return HTTP_INSHOOT.post('/admin/login/adminLogin', params)
}
// 获取地址
export const getAreaList = params => {
  return HTTP_INSHOOT.get('/pub/area/area_list')
}

/** 首页路由 */

// 首页
const Personalaudit = () => import('@/views/auditModule/personalaudit/index')

const personalauditRouter = [
  {
    path: '/personalaudit',
    redirect: '/index',
    component: () => import('../layout/index.vue'),
    children: [
      {
        path: '/personalaudit',
        name: 'personalaudit',
        component: Personalaudit,
        meta: {
          title: '个人认证'
        }
      }
    ]
  }
]
export default personalauditRouter

import { HSUC } from 'hsuc'
console.log(HSUC)
const HSUC_VUE_APP_URL = new HSUC({
  appCode: 118,
  baseURL: process.env.VUE_APP_INSHOOT_URL,
  debug: false
})
export {
  HSUC_VUE_APP_URL
}

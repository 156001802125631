/** 首页路由 */

// 首页
const Dispose = () => import('@/views/disposeModule/dispose.vue')

const EditSelection = () => import('@/views/disposeModule/editSelection.vue')
const disposeRouter = [
  {
    path: '/disposePage',
    redirect: '/dispose',
    component: () => import('../layout/index.vue'),
    children: [
      {
        path: '/dispose',
        name: 'dispose',
        component: Dispose,
        meta: {
          title: '配置首页'
        }
      },
      {
        path: '/editSelection',
        name: 'editSelection',
        component: EditSelection,
        meta: {
          title: '编辑精选'
        }
      }
    ]
  }
]
export default disposeRouter

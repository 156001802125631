function EventManger () {
  this.list = {}
}

EventManger.prototype.add = function (eventName, handler) {
  if (typeof eventName !== 'string' || typeof handler !== 'function') {
    return
  }
  if (!this.list[eventName]) {
    this.list[eventName] = []
  }
  this.list[eventName].push(handler)
}

EventManger.prototype.remove = function (eventName, handler) {
  if (typeof eventName !== 'string' || typeof handler !== 'function') {
    return
  }
  this.list[eventName].forEach((oldHandler, index) => {
    if (oldHandler === handler) {
      this.list[eventName].splice(index, 1)
    }
  })
}

EventManger.prototype.emit = function (eventName) {
  if (typeof eventName !== 'string' || !this.list[eventName]) {
    return
  }
  this.list[eventName].forEach(cb => {
    const args = Array.prototype.slice.call(arguments)
    args.shift() // 去除事件名称保留其余参数传递给用户的 handler
    cb.call(this, ...args)
  })
}

export {
  EventManger
}

/** 首页路由 */

// 首页
const Home = () => import('@/views/homeModule/index')
// 列表
const List = () => import('@/views/homeModule/list')

const homeRouter = [
  {
    path: '/',
    redirect: '/login',
    component: () => import('../layout/index.vue'),
    children: [
      {
        path: '/index',
        name: 'index',
        redirect: '/personalaudit',
        component: Home,
        meta: {
          title: '其他'
        }
      },
      {
        path: '/list',
        name: 'list',
        component: List,
        meta: {
          title: '列表'
        }
      }
    ]
  }
]
export default homeRouter

/**
 * 书写规则：
 * 基于axios二次封装，接口和方法写一起，页面只需要传入参数即可；
 * 方法名：get请求：getName,post请求：postName；
 */
/**
 * 作品审核
 */

import { HTTP_INSHOOT } from '../axios'

//  作品列表
export const getWorksList = params => {
  return HTTP_INSHOOT.get('/Admin/Work/getworklist', params)
}

//  作品详情
export const getWorksDetail = params => {
  return HTTP_INSHOOT.get('/Admin/Work/getworkinfo', params)
}

//  作品审核
export const postExaminework = params => {
  return HTTP_INSHOOT.get('/Admin/Work/examinework', params)
}

/*
数据管理中心
*/
import { createStore } from 'vuex'
const context = require.context('./modules', false, /\.js$/)
const moduleStores = {}

context.keys().forEach(key => {
  const fileName = key.slice(2, -3)
  const fileModule = context(key).default
  moduleStores[fileName] = {
    ...fileModule
  }
})
const store = createStore({
  modules: {
    ...moduleStores
  }
})

export default store

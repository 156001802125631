import {
  createApp
} from 'vue'
import App from './App.vue'
// 全量引入element-plus
import ElementPlus from 'element-plus'
import './assets/styles/element-variables.scss'
// 引入路由
import router from './router/index'
// 引入vuex
import store from './store/index'
// 引入axios
import $api from './service/apiService'
import locale from 'element-plus/lib/locale/lang/zh-cn'
import moment from './utils/index'
import '@/assets/icons/iconfont.css'
import '@/assets/icons/iconfont.js'

// 播放器
import HSPlayer from './components/player/index.js'

import HsAvatar from '@/components/Avatar/index.js'
// 引入事件总线
import mitt from 'mitt'
// 创建app
const app = createApp(App)
// 注册ElementPlus
app.use(ElementPlus, { locale })
// 注册路由
app.use(router)
// 注册vuex
app.use(store)
app.use(HSPlayer)
app.use(HsAvatar)
// 全局挂载api moment
app.config.globalProperties.$api = $api
app.config.globalProperties.$moment = moment
// 挂载事务总线
app.config.globalProperties.$bus = mitt()

// 挂载实例
app.mount('#app')

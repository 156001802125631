/* 整合路由资源，集中管理 */
import { createRouter, createWebHistory } from 'vue-router'
// 动态解析当前路由模块下的文件
const importFile = require.context('@/router', false, /\.js$/)
const lib = importFile.keys()
const fileObj = {}
// 动态导入路由文件
function importAll (r) {
  r.keys().forEach((key) => {
    fileObj[key] = r(key).default
  })
}
importAll(importFile)
const routerArry = lib.filter((file, index) => {
  return file !== './index.js'
})
// console.log(routeList)
const routeList = []
routerArry.forEach(routerItem => {
  routeList.push(fileObj[routerItem])
})
const routes = routeList.flat()
// console.log(routes)

const router = createRouter({
  history: createWebHistory(),
  routes
})
// 全局路由守卫
router.beforeEach((to, from, next) => {
  next()
})
router.afterEach((to, from) => {
  // 逻辑操作
})
export default router

/* 首页 */

const store = {

  state: {
    detailInfo: {}
  },
  getters: {
    getDetailInfo (state) {
      return state.userInfo
    }
  },
  mutations: {
    SET_DETAIL_INFO: (state, data) => {
      state.detailInfo = data
    }
  },
  // 异步通知
  actions: {
    updateInfo ({ commit }, data) {
      commit('SET_DETAIL_INFO', data)
    }
  }
}
export default store

/**
 * 书写规则：
 * 基于axios二次封装，接口和方法写一起，页面只需要传入参数即可；
 * 方法名：get请求：getName,post请求：postName；
 */
/**
 * 登录
 */

import { HTTP_INSHOOT } from '../axios'

// admin登录
export const loginAdmin = params => {
  return HTTP_INSHOOT.post('/admin/login/adminLogin', params)
}

//  登出
export const adminlogout = params => {
  return HTTP_INSHOOT.post('/admin/logout/adminlogout')
}

/* 用户中心模块 */

const store = {

  state: {
    userInfo: {}
  },
  getters: {
    getUserInfo (state) {
      return state.userInfo
    }
  },
  mutations: {
    SET_USER_INFO: (state, data) => {
      state.userInfo = data
    }
  },
  // 异步通知
  actions: {
    updateInfo ({ commit }, data) {
      commit('SET_USER_INFO', data)
    }
  }
}
export default store

/** 首页路由 */

// 首页
const worksVideos = () => import('@/views/worksModule/worksVideos/worksVideos.vue')

const worksImages = () => import('@/views/worksModule/worksImages/worksImages.vue')

const worksRouter = [
  {
    path: '/',
    component: () => import('../layout/index.vue'),
    children: [
      {
        path: '/worksVideos',
        name: 'worksVideos',
        component: worksVideos,
        meta: {
          title: '视频审核'
        }
      },
      {
        path: '/worksImages',
        name: 'worksImages',
        component: worksImages,
        meta: {
          title: '图片审核'
        }
      }
    ]
  }
]
export default worksRouter

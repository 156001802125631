import pkg from '../../../package.json'

/**
 * 视频截屏
 * @param {VideoElement} video 截屏的视频元素
 * @param {String} name 截屏文件名称
 * @param {Number} width 截图宽度
 * @param {Number} height 截图高度
 */
function capture (video, name, width, height) {
  const canvas = document.createElement('canvas')
  const cvs = canvas.getContext('2d')
  canvas.width = width
  canvas.height = height

  cvs.drawImage(video, 0, 0, width, height)
  canvas.toBlob((blob) => {
    const href = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = href
    a.download = name || `capture_${Date.now()}.png`
    document.documentElement.appendChild(a)
    a.click()
    a.parentNode.removeChild(a)
  })
}

export default [
  {
    id: 'capture',
    title: '截取当前画面',
    fun (video, name) {
      const width = video.videoWidth
      const height = video.videoHeight
      return new Promise((resolve, reject) => {
        try {
          capture(video, name, width, height)
          resolve()
        } catch (err) {
          reject(new Error('因安全限制该视频无法生成截图'))
        }
      })
    }
  },
  {
    id: 'about',
    title: `HSPlayer ${pkg.version}`,
    fun () { }
  }
]

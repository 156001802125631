export default {
  hls: {
    bufferStalledError: '缓冲中...'
  },
  video: {
    1: '视频获取中止',
    2: '下载视频错误',
    3: '解码错误',
    4: '不支持的媒体格式'
  }
}

/** 首页路由 */

// 首页
const Enterpriseaudit = () => import('@/views/auditModule/enterpriseaudit/index')

const enterpriseauditRouter = [
  {
    path: '/enterpriseaudit',
    redirect: '/index',
    component: () => import('../layout/index.vue'),
    children: [
      {
        path: '/enterpriseaudit',
        name: 'enterpriseaudit',
        component: Enterpriseaudit,
        meta: {
          title: '机构认证'
        }
      }
    ]
  }
]
export default enterpriseauditRouter

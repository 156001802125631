/**
 * 书写规则：
 * 基于axios二次封装，接口和方法写一起，页面只需要传入参数即可；
 * 方法名：get请求：getName,post请求：postName；
 */
/**
 * 配置首页
 */

import { HTTP_INSHOOT } from '../axios'

//  模块儿列表
export const getBlockList = params => {
  return HTTP_INSHOOT.get('/admin/blockconf/block_list')
}

// 添加模块
export const postAddBlockConf = params => {
  return HTTP_INSHOOT.post('/admin/blockconf/add_block_conf', params)
}

// 编辑模块
export const postEditBlockConf = params => {
  return HTTP_INSHOOT.post('/admin/blockconf/save_block_conf', params)
}

// 模块排序
export const postBlockSort = params => {
  return HTTP_INSHOOT.post('/admin/blockconf/save_block_sort', params)
}

// 模块显示隐藏
export const postBlockHide = params => {
  return HTTP_INSHOOT.post('/admin/blockconf/save_block_hide', params)
}

// 模块删除
export const postBlockDel = params => {
  return HTTP_INSHOOT.post('/admin/blockconf/del_block', params)
}

// 模块下已有作品列表
export const getRelList = params => {
  return HTTP_INSHOOT.get('/admin/blockconf/rel_list', params)
}

// 模块下已有作品列表排序
export const postRelSort = params => {
  return HTTP_INSHOOT.post('/admin/blockconf/save_rel_sort', params)
}

// 模块綁定作品
export const postAddRel = params => {
  return HTTP_INSHOOT.post('/admin/blockconf/add_rel', params)
}

// 模块下作品删除
export const postDelRel = params => {
  return HTTP_INSHOOT.post('/admin/blockconf/del_rel', params)
}

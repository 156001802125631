<template>
  <div>
    <el-config-provider :locale="locale">
      <router-view></router-view>
    </el-config-provider>

  </div>
</template>

<script>

// 国际化
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
export default {
  name: 'App',
  components: {
  },
  data () {
    return {
      locale: zhCn
    }
  }
}

</script>

<style>
</style>

<template>
  <div class='hs-avatar'
       :style="style"
       :class="{circle}">
    <div class="triggler">
      <img :src="url || 'https://cdn.hsuanyuen.com/Fe-public/inshoot-file/user/default-user.png'">
      <div class="sign"
           :class="type === 1 ? 'sign-personal' : 'sign-organ'"
           v-if="status === 1">
        <i class="iconfont iconv"></i>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    url: { // 头像地址
      type: String,
      default: ''
    },
    width: { // 宽高（宽高相同）
      type: [Number, String],
      default: '30px'
    },
    circle: { // 启用圆形头像
      type: Boolean,
      default: true
    },
    status: {
      type: Number
    },
    type: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      userConfig: {},
      currentMode: 'PICTURE',
      zIndex: 1
    }
  },
  created () {
  },
  methods: {
  },
  computed: {
    style () {
      return {
        ...this.userConfig,
        width: this.width,
        height: this.width,
        zIndex: this.zIndex
      }
    }
  }
}
</script>
<style lang='scss' scoped>
.hs-avatar {
  border: 1px solid transparent;
  display: inline-block;
  position: relative;
  border-radius: 50%;
  &.circle {
    border-radius: 50%;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
  .text {
    text-align: center;
    color: #fff;
    width: 100%;
    height: 100%;
    background-color: purple;
  }
  .triggler {
    cursor: pointer;
    user-select: none;
    height: inherit;
    position: relative;
    border-radius: 50%;
    .sign {
      width: 16px;
      height: 16px;
      border: 1px solid #fff;
      border-radius: 50%;
      text-align: center;
      line-height: 16px;
      color: #fff;
      position: absolute;
      right: -5px;
      bottom: -5px;
      z-index: 99;
      i {
        font-size: 12px;
      }
    }
    .sign-personal {
      background-color: #fc832c;
    }
    .sign-organ {
      background-color: #198de7;
    }
  }
}
</style>


import axios from 'axios'
import {
  HSUC_VUE_APP_URL
} from './hsuc'
import { getType, expandFormData } from '@/utils/utils.js'
const CancelToken = axios.CancelToken
const pending = new Map()

class HttpRequest {
  constructor(url) {
    this.baseURL = process.env[`VUE_APP_${url}_URL`]
    this.withCredentials = true
    this.timeout = 10000
  }

  // 中间件
  middleRequest (options = {}) {
    const instance = axios.create()
    const config = {
      ...options,
      baseURL: this.baseURL,
      timeout: this.timeout,
      withCredentials: this.withCredentials
    }
    this.setInterceptors(instance, options.url)
    return instance(config)
  }

  // GET
  get (url, params, options = {}) {
    const GET = this.middleRequest({
      method: 'get',
      url: url,
      params: params,
      ...options
    })
    return GET
  }

  // POST
  post (url, params, options = {}) {
    const POST = this.middleRequest({
      method: 'post',
      url: url,
      data: params,
      ...options
    })
    return POST
  }

  // 签名
  sign (config) {
    let HSUC_SIGN = null
    switch (config.baseURL) {
      case process.env.VUE_APP_INSHOOT_URL:
        HSUC_SIGN = HSUC_VUE_APP_URL
        break
      default:
        break
    }
    const data = config.method === 'get' ? config.params : getType(config.data) === 'FormData' ? expandFormData(config.data) : config.data
    if (config.method === 'get') {
      config.params = HSUC_SIGN.sign(data, config.method)
    } else {
      config.data = HSUC_SIGN.sign(getType(config.data) === 'FormData' ? config.data : data, config.method)
    }
  }

  // 撤销重复请求
  changePending (config) {
    this.sign(config)
    let requestKey = ''
    if (config.method === 'post') {
      requestKey = config.data
    }
    const url = [config.method, config.url, requestKey].join('&')
    config.cancelToken = config.cancelToken || new CancelToken((cancel) => {
      if (pending.has(url)) {
        pending.set(url, cancel)
      } else if (pending.has(url)) {
        const cancel = pending.get(url)
        cancel(url)
        pending.delete(url)
      }
    })
  }

  // 清空清空
  clearPending () {
    for (const [url, cancel] of this.pending) {
      cancel(url)
    }
    this.pending.clear()
  }

  // 拦截器
  setInterceptors (instance) {
    this.requestInterceptors(instance)
    this.responseInterceptors(instance)
  }

  // 请求拦截器
  requestInterceptors (instance) {
    instance.interceptors.request.use(config => {
      this.changePending(config)
      return config
    }, () => { })
  }

  // 响应拦截器
  responseInterceptors (instance) {
    instance.interceptors.response.use(response => {
      if (response.status === 200) {
        const res = response.data
        if (+res.code === 0) {
          return Promise.resolve(response.data)
        } else if ([135401, 105046].includes(res.code)) {
          // 105046：试用期已过
          // 105401：登录失效
          HSUC_VUE_APP_URL.clearLocalInfo()
          window.location.href = '/login'
          return Promise.reject(response.data.data.msg ? response.data.data.msg : response.data.msg)
        } else {
          const error = response.data.msg ? response.data.msg : response.data.data.msg
          return Promise.reject(error)
        }
      } else {
        return Promise.reject(new Error('服务器开小差了，请刷新重试'))
      }
    }, () => {
      const err = '服务器开小差了，请刷新重试'
      return Promise.reject(err)
    })
  }
}

const HTTP_INSHOOT = new HttpRequest('INSHOOT')

export { HTTP_INSHOOT }
